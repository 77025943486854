import React from "react"
import style from "./headermobile.module.css"
import { Link } from "gatsby"
import logo_notext from "../../imgs/logo-notext-nobg.png"
import { useState } from "react"
import useClickedOutside from "../../publicfunctions/useClickedOutside"
import { useRef } from "react"

const HeaderMobile = () => {
  const [menuOpened, setMenuOpened] = useState(false)
  const menuRef = useRef(null)

  useClickedOutside(menuRef, () => (menuOpened ? setMenuOpened(false) : null))

  return (
    <div className={style.headerContainer}>
      <div className={style.attention}>
        Kedves Érdeklődők! Gyermekvállalás miatt a rendelésem várhatóan 2025 tavaszáig szünetel, ezért átmenetileg nem tudok új klienseket fogadni. Megértésüket köszönöm!
      </div>
      <header className={style.header}>
        <Link to="/mobil">
          <img id={style.mobileheaderlogo} src={logo_notext} alt="Logó"></img>
        </Link>
        <Link to="/mobil">
          <h3 className={style.nev}>Jakab-Jánosi Eszter</h3>
        </Link>
        <button
          aria-label="Navigáció megnyitása"
          onClick={() => setMenuOpened(!menuOpened)}
          onKeyDown={() => setMenuOpened(!menuOpened)}
        />
      </header>
      <div
        ref={menuRef}
        className={`${style.menubar} ${menuOpened ? style.menuopen : null}`}
      >
        <p>
          <Link to="/mobil">Kezdőoldal</Link>
        </p>
        <p>
          <Link to="/szolgaltatasokmobil">Szolgáltatások</Link>
        </p>
        <p>
          <Link to="/arakmobil">Árak</Link>
        </p>
        <p>
          <Link to="/kapcsolatmobil">Kapcsolat</Link>
        </p>
      </div>
    </div>
  )
}

export default HeaderMobile
