import React, { useEffect } from "react"

const useClickedOutside = (ref, func, not_ref) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        if ((not_ref && !not_ref.current.contains(event.target)) || !not_ref) {
          func()
        }
      }
    }

    window.addEventListener("mousedown", handleClickOutside)
    return () => window.removeEventListener("mousedown", handleClickOutside)
  })
}

export default useClickedOutside
