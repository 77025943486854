import React from "react"

import ImageButton from "../imagebutton/Imagebutton"
import gyermek from "../../../imgs/gyermek.jpg"
import par from "../../../imgs/par.jpg"
import csalad from "../../../imgs/csalad.jpg"
import style from "./layout.module.css"
import { useState } from "react"
import Description from "../Description/Description"

const Layout = () => {
  const [state, setState] = useState(null)

  return (
    <div className={style.container}>
      <ImageButton
        state={state}
        setState={setState}
        number={0}
        text="Gyermepszichológia, nevelési tanácsadás"
        img={gyermek}
      />
      <ImageButton
        state={state}
        setState={setState}
        number={1}
        text="Családterápia"
        img={csalad}
      />
      <ImageButton
        state={state}
        setState={setState}
        number={2}
        text="Párterápia"
        img={par}
      />
      <Description state={state} />
    </div>
  )
}

export default Layout
