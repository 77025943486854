import React from "react"
import Layout from "../components/servicespage/Layout/Layout"
import Footer from "../components/Footer/Footer"
import HeaderMobile from "../components/Header/HeaderMobile"
import { Helmet } from "react-helmet"

const ServicesMobil = () => {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://jakabjanosieszter.hu/szolgaltatasok"
        />
        <title>Szolgáltatások - Jakab-Jánosi Eszter, érdi pszichológus</title>
      </Helmet>
      <HeaderMobile />
      <Layout />
      <Footer />
    </>
  )
}

export default ServicesMobil
