import React from "react"

import style from "./description.module.css"

const Description = props => {
  return (
    <div className={style.container}>
      {props.state === 0 ? (
        <p>
          Amennyiben a szülők úgy érzik, gyermekük problémás viselkedésével
          kapcsolatban már tehetetlenné váltak, a helyzet már nagyon megterhelő
          lelkileg a családtagok számára, én kívülről szemlélve, a problémát
          alaposan feltárva, szakértőként tudok ebben segítő kezet nyújtani. A
          folyamat első lépése minden esetben a szülőkkel való konzultáció,
          ilyenkor részletesen átbeszéljük a probléma mibenlétét, felteszem a
          szükséges kérdéseket. Ezt követően 3-4 alkalommal találkozom a
          gyermekkel, az alkalmak során különböző diagnosztikus értékű rajzok,
          projektív tesztek segítségével mérem fel a gyermek pszichés állapotát.
          Ezek alapján a szülőknek visszajelzést adok, közösen döntést hozunk
          arról, hogy szükséges-e a továbbiakban hozzám járniuk tanácsadásra,
          illetve mi az, amit otthoni körülmények között saját maguk tehetnek
          annak érdekében, hogy a helyzet pozitív irányba változzon.
        </p>
      ) : null}
      {props.state === 1 ? (
        <p>
          Sok esetben előfordul, hogy a gyermekkel kapcsolatban jelzett probléma
          feldolgozásához a teljes család bevonására is szükség van, ilyenkor az
          egyes családtagok közötti kapcsolatokon keresztül, családterápia
          formájában közelítjük meg a gyermeknél jelentkező tünetet. Emellett
          természetesen az is jellemző, hogy a családban nem a gyermek
          problémáján keresztül válik szükségessé a családterápia, hanem egyes
          tagok közötti komoly nézeteltérések, újonnan létesült mozaikcsaládban
          felmerülő problémák vagy egyéb okok miatt kérnek tőlem segítséget.
          Ilyenkor a családban, mint rendszerben keressük meg a hibás működési
          módokat, kapcsolati minőségeket, melyek változást igényelnek. Közösen,
          helyben végzett feladatok és házi feladatok útján, speciális
          módszerekkel tudunk dolgozni azon, hogy visszaállítsuk a megfelelő
          egyensúlyt. Az esetek többségében a családterápiás üléseket két
          terapeuta vezeti, egy alkalom nagyjából másfél órát vesz igénybe,
          melyekre kéthetente kerítünk sort.
        </p>
      ) : null}
      {props.state === 2 ? (
        <p>
          Egyes megoldhatatlannak tűnő párkapcsolati nehézségek esetén (pl.
          elhidegülés, hűtlenség, heves viták, folyamatos konfliktusok,
          érdekütközések) nagy segítséget jelenthet, ha egy külső, hozzáértő
          nézőponttal kiegészülve keressük közösen a megoldást. Ebben az esetben
          feladatomnak tekintem különböző, hatékony módszereket alkalmazva a pár
          tagjait segíteni egymás jobb megértésében, közös cél keresésében és
          annak elérésében. A párterápia során elsősorban arra törekszem, hogy a
          pár tagjai a terápiás térben mindamellett, hogy lehetőséget kapnak
          saját érzelmeik és gondolataik kifejezésére, egyben nagyobb rálátást
          nyerhessenek a párjukéra is, ezáltal érzelmileg közelebb kerüljenek
          egymáshoz. Ez sok esetben hosszú, kitartó munkát igényel a pár
          tagjaitól, ám ehhez megbízható segítséget és eszközöket nyújtok.  Az
          esetek többségében a páterápiát két terapeuta vezeti (egy férfi és egy
          nő), egy alaklom nagyjából másfél órát vesz igénybe, melyekre
          kéthetente kerítünk sort.
        </p>
      ) : null}
    </div>
  )
}

export default Description
