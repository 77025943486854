import React from "react"

import style from "./imagebutton.module.css"

const ImageButton = props => {
  return (
    <div className={style.container}>
      <div>
        <img
          src={props.img}
          alt=""
          className={props.state === props.number ? style.selected : null}
          onClick={() => props.setState(props.number)}
          onKeyDown={() => props.setState(props.number)}
        />
      </div>
      <p
        className={style.text}
        onClick={() => props.setState(props.number)}
        onKeyDown={() => props.setState(props.number)}
      >
        {props.text}
      </p>
    </div>
  )
}

export default ImageButton
